#news_sidepanel_open {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 400px;
  z-index: 1000;
  height: 100vh;
  visibility: visible;
  transition: width 2s;
  overflow-y: auto;
}

#news_sidepanel_close {
  width: 0px;
  z-index: 1000;
  transition: all 1s ease 1s;
  height: 100vh;
}

#news_sidepanel_open {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.language_icon {
  svg {
    width: 21px !important;
    height: auto !important;
  }
}

.socialModal__btn-bckgrnd {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: linear-gradient(45deg, #2962ff, #3949ab) !important;
}
.horizontal__space {
  width: 3rem;
}

.modal__facebook-btn {
  background: linear-gradient(45deg, #2962ff, #3949ab) !important;
  font-size: 18px;
}
.modal__instagram-btn {
  background: linear-gradient(45deg, hsl(287, 65%, 40%), #ff6e40) !important;
  font-size: 18px;
}
.modal__twitter-btn {
  background: linear-gradient(45deg, #0288d1, #26c6da) !important;
  font-size: 18px;
}
.modal__linkedin-btn {
  background: linear-gradient(45deg, #cfd8dc, #546e7a) !important;
  font-size: 18px;
}

#account_post-modal {
  max-height: 40rem;
  overflow: scroll;
}
@media (max-width: 992px) {
  .social-table {
    overflow: hidden !important;
    overflow-x: scroll !important;
  }
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
  cursor: pointer;
}

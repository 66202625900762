.map__container {
  width: 100%;
  height: 100%;

  .worldmap__figure-container {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #9a73b873;
    margin-bottom: 0;
  }
  .jvectormap-container {
    // svg {
    //   height: 380px !important;
    // }
  }
  .worldmap__figure-container {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.manitor__filter {
  padding: 1rem 1rem;
  overflow: scroll;
  border-radius: 5px;
  &-card {
    background-color: #ededed88;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0.3rem 0 0.2rem 0.5rem;
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }
  &-flag {
    background-color: #ededed88;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow: scroll;
    padding: 0.2rem 0 0 0.5rem;
    img {
      width: 35px;
      height: 35px;
      padding: 0.3rem;
      background-color: #dddddd;
      border-radius: 50%;
    }
  }
}
.tableTag__body {
  border: 1px solid rgb(192, 192, 192);
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 0.3rem;
  font-size: 11px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}
.compare__modal-body {
  &-img {
    width: 300px;
  }
}

.approved-tag {
  background-color: #effbf6 !important;
  color: #73d79f !important;
  border-color: #73d79f;
  font-weight: 700;
  padding: 0.5rem !important;
  svg {
    fill: #73d79f !important;
    width: 18px;
  }
}

.hidden-tag {
  background-color: #f1416c2e !important;
  color: #f1416c !important;
  font-weight: 700;
  border-color: #f1416c !important;
  padding: 0.5rem !important;
  svg {
    width: 18px;
    fill: #f1416c !important;
  }
}

.whitelist-tag {
  background-color: #e4e6ef4a !important;
  padding: 0.5rem !important;
  svg {
    width: 18px;
  }
}

.paused-tag {
  background-color: #ffc7003d !important;
  color: #ffc700 !important;
  font-weight: 700;
  padding: 0.5rem !important;
  border-color: #ffc700 !important;
  svg {
    width: 18px;
    fill: #ffc700 !important;
  }
}

/* empty commit */
.table > :not(:first-child) {
  border-top: 0px !important;
}

.pagination-container {
  float: right;
  display: flex;
  list-style-type: none;
}
iframe{
  /* position: relative !important; */
  z-index:1 !important;
}
.grecaptcha-badge {
  left: 2rem !important;
}
.pagination-container .pagination-item {
  padding: 0 14px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  background-color: rgba(0, 0, 0, 0.08);
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.react-tabs__tab-list {
  border-bottom: 0 !important;
}

.react-tabs__tab--selected {
  border-color: #fff !important;
  background: transparent !important;
}

.react-tabs__tab--selected .btn {
  color: #ffffff !important;
  border-color: #009ef7 !important;
  background-color: #009ef7 !important;
}

.react-tabs__tab--selected i {
  color: #ffffff !important;
}

.svg-icon.svg-icon-20x svg {
  height: 11.5rem !important;
  width: 11.5rem !important;
}

.fileItemCard:hover {
  opacity: 0.9;
  cursor: pointer;
}

.invoice-card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.invoice-payment p {
  margin-bottom: 0;
}

.invoice-payment li {
  border-top: 1px solid gainsboro;
  margin-top: 10px;
  padding-top: 10px;
}
.invoice-payment li:first-child {
  border: none;
  margin: 0;
}

.subscriptions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.fv-help-block{
  color: red !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

.toro_post_dropdown::after {
  display: none !important;
  z-index: 100;
}

.Toastify__toast--info {
  z-index: 100 !important;
}

.Toastify__toast--success {
  z-index: 100 !important;
}

.Toastify__toast--warning {
  z-index: 100 !important;
}

.Toastify__toast--error {
  z-index: 100 !important;
}

@media screen and (max-width: 992px) {
  .invoice-card {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  .subscriptions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  .invoice-card {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .subscriptions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  .coupons-dropdown {
    transform: none !important;
    top: 40px !important;
    right: 30px !important;
  }

  .more-dropdown-button:after {
    content: none !important;
  }
}

.dropdown-toggle::after {
  display: none;
}
.fileItem__text-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  strong {
    margin-right: 0.5rem;
  }
}
.fileItem__text-url {
  cursor: pointer;
}

#sidepanel_open {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 400px;
  z-index: 1000;
  height: 100vh;
  visibility: visible;
  transition: width 2s;
  overflow-y: auto;
}
#sidepanel_close {
  width: 0px;
  z-index: 1000;
  transition: all 1s ease 1s;
  height: 100vh;
}

#sidepanel_open {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.filesList_table-body {
  overflow: hidden !important;
  overflow-x: scroll !important;
}

.badge-image {
  transition: transform 0.5s;
}

.badge-image:hover {
  transform: scale(1.1);
}

.latestItems_body {
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  gap: 1rem;
  justify-content: center;
}

.latestItems_body-item {
  transition: transform 0.2s;
  cursor: pointer;
  min-height: 14rem;

  img {
    object-fit: cover;
    width: 110px;
    height: 140px;
    border: none;
  }

  &-title {
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 600px) {
  .latestItems_body {
    grid-template-columns: repeat(2, 0fr);
  }
}

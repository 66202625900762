.badge_image-wrapper {
  img {
    object-position: top;
  }
}
@media (max-width: 768px) {
  .badge_image-wrapper {
    img {
      width: 350px !important;
    }
  }
}
